@font-face {
    font-family: 'Pristina';
    src: url('./PRISTINA.woff2') format('woff2');
}

.footer.wrapper {
    background-color: #3b1515;
    min-height: 80px;
    width: 100%;
    display: flex;
    justify-items: center;
}
.footer.content {
    max-width: 1200px;
    width: 100%;
    margin:auto;
    padding: 20px 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color:white;
}
.footer.logo {
    display: flex;
    justify-items: center;
    width: fit-content;
}
.footer.logo img {
    height: 50px;
}
.footer.info {text-align: left;margin-left: 10px;}
.footer.info .title { font-family: Pristina; font-size: 1.7rem;line-height: 1;margin-top: 5px; }
.footer.info .contact { font-size: 1rem; }


.footer.links {
    width: fit-content;
}
.footer.links ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.footer.links .default { font-size: 0.8rem;}
.footer.links .small { font-size: 0.6rem; }
.footer.links .divider {margin: 0 5px;}
.footer.links li a, .footer.links li a:active, .footer.links li a:hover {text-decoration: none;color:rgb(207, 207, 207);}
 
@media screen and (max-width:550px) {
    .footer.content {flex-direction: column;}
    .footer.links {margin-top:30px;}
}
