.home.top {
    width: 100%;
    height:60vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 30px;
}
.home.center { text-align: center;}
.home.content { padding: 0 15px;margin-bottom: 100px; }
.home.icons {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}
.home.icon {
    max-width: 300px;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
}
.home.icon img { height: 100px;opacity: 0.7;margin-bottom: 20px;}

@media screen and (max-width:550px) {
    .home.icons  { flex-direction: column;}
    .home.icon {max-width: none;margin-bottom: 50px;}
}
