/* Font for logo */
@font-face {
    font-family: 'Harlow';
    src: url('./HARLOWSI.woff2') format('woff2');
}
@font-face {
    font-family: 'Pristina';
    src: url('./PRISTINA.woff2') format('woff2');
}

.navbar.wrapper {
    background-color: #5e2424;
    width: 100%;
    z-index: 2;
}
.navbar.content {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    width:95%;
    max-width:1200px;
    box-sizing: border-box;
    margin:auto;
}

.navbar.logo {
    width: fit-content;
    display: flex;
    align-items: center;
}
.navbar.logo img {
    width: 70px;
    margin-right: 10px;
    user-select: none;
    border-radius: 5px;
}


.navbar.title {
    color:white;
    font-family: Pristina;
    font-size: 50px;
    margin-top:10px;
    user-select: none;
    white-space: nowrap;
}


/* NAVBAR MENU ICON - start */
.navbar.links {
    display: table-row;
}
.navbar.links li {
    display: table-cell;
    vertical-align: middle;
    border-radius: 10px;
    padding: 10px 1.1rem;
    user-select: none;
    
}
.navbar.links li a {
    text-decoration: none;
    color:white;
    font-size: 1rem;
}

.navbar.links li:hover{
    color:rgb(241, 241, 241);
    background: rgb(77, 9, 9);
    transition: 0.5s ease-out;
}
/* NAVBAR MENU ICON - stop */

/* NAVBAR MENU ICON SUPPORT - start */
.navbar.button {
    display: none;
}
.navbar.button img {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(99deg) brightness(105%) contrast(104%); /* calculate color using: https://codepen.io/sosuke/pen/Pjoqqp */
    width:50px;
}

.navbar.navbar.language:hover {background: none;}
.navbar.language div {
    width: 80px;
    display: flex;
    justify-content: space-between;
    margin:auto;
}
.navbar.language img { height: 30px; cursor: pointer; }

@media screen and (max-width:1150px){
    .navbar.button {
        display:flex;
        height:70px;
        width:65px;
        user-select: none;
    }
    .navbar.content {
        width: 100%;
        padding-left: 20px;
    }

    .navbar.links {
        opacity: 0;
        display:flex;
        flex-direction: column;
        position: absolute;
        left:-100%;
        top:70px;
        width:100%;
        margin: 0;
        padding:0;
        transition: all 0.5s ease;
        z-index: 2;
    }
    .navbar.links.active {
        opacity: 1;
        left:0px;
        transition: all 0.5s ease;        
    }
    .navbar.links li {
        width: 100%;
        background-color: white;
        text-align: center;
        border-radius: 0;
        margin: 0;
        padding: 5px 0px;
    }
    .navbar.links li a {
        color:black;
        font-size: 1.3rem;
    }
    .navbar.links li:hover, .navbar.navbar.language:hover {
        background-color: rgb(223, 223, 223);
        color:black;
        transition: none;
    }

}
@media screen and (max-width:500px) {
    .navbar.title {font-size: 30px;}
}
@media screen and (max-width:450px) {
    .navbar.content {width: 100%;}
    .navbar.logo { position: absolute;left:5px;}
    .navbar.button {position: absolute;right: 0px;}
    
}

/* NAVBAR MENU ICON SUPPORT - end */
/*
.active-li {
    background-color: rgb(58, 58, 58);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 100%;
}


.navbar-link {
    font-size: 1.7rem;
    text-decoration: none;
    padding: 0.5rem 1.5rem;
    color:white;

}
.navbar-link:hover{
    color:rgb(241, 241, 241);
    background: #474449;
    border-radius: 4px;
    transition: 0.5s ease-out;
}
*/