.story.wrapper {
    background-color: rgb(31, 31, 31);
    width: 100vw;
    overflow:hidden;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    overscroll-behavior-y: none;
    -webkit-overflow-scrolling: none;
    /*pointer-events: none;*/
}
.story.wrapper.mobile { height: -webkit-fill-available;}
.story.wrapper.desktop {height: 100vh; }

.story.container { position: relative;}
.story.container img {
    object-fit: fil;display: block;
    pointer-events: all;
}
.story.container.height img { height: 100vh;width: auto;}
.story.container.width img {width: 100vw;height: auto;}
.story.container .hide {opacity: 0;}
.story.container .main-image {
    position: absolute;
    top:0px;
    left:0px;
    transition: 0.5s;
}
.story.container .main-image.move { transition: none;}
.story.container .main-image.go-left { left:-100%;opacity: 0; transition: 0.5s;}
.story.container .main-image.go-right { left:100%;opacity: 0; transition: 0.5s;}


.story.test.float {
    position: absolute;
    top:50%;
    left:50%;
    background-color: black;
    color:white;
}

