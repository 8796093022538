* {box-sizing: border-box;border-collapse: collapse;}
ul {list-style: none;padding:0;}
/*img { pointer-events: none; }*/
html, body {
  width: 100%;
  height: 100%;
  border:none;
  /*position: fixed;*/
  overscroll-behavior: none;
  -webkit-overflow-scrolling: none;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.h1, .h2, .h3 { width: fit-content;margin:0;padding:0;}
.h1, .h3 {font-family:cursive}
.h1 {
  font-size: 2rem;
  font-weight: 600;
  display: block;
  color:rgb(100, 100, 100)
}
.h1.inline { display: inline;}
.h3 {
  font-weight: 600;
  font-size: 1.5rem;
  color:rgb(110, 110, 110)
}

.page.content {
  max-width: 1200px;
  margin:auto;
  padding-top:50px;
}
.center {margin-inline:auto;}

/* Full vertical view with growable div */
.page.full.vertical {display: flex;flex-direction: column;min-height: 100vh;}
.page.full.vertical .grow { flex-grow: 1;}


.full-screen {
  position: absolute;
  top:45%;
  left:50%;
  z-index: 20;
}
.full-screen.hide {display: none;}
.full-screen button {
  position: relative;
  left:-50%;
  padding: 30px 30px;
  border-radius: 20px;
  background-color: rgba(70, 70, 70, 0.6);
  border: 10px solid rgba(110, 110, 110, 0.1);
  color:white;
  font-weight: 700;
  font-size: 3rem;
}

@media screen and (max-width:550px) {
  .h1 { font-size: 1.5rem;}
  .h3 { font-size: 1.2rem;}
}