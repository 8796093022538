.books.book {
    display: flex;
    justify-content:flex-start;
    align-items: flex-start;
    min-width: 500px;
    margin-bottom: 50px;
}
.books.book img {
    max-width: 150px;
    max-height: 150px;
    height: 100%;
    object-fit: contain;
}
.books.info { margin-left: 20px;}
.books.info .title { font-size: 1.1rem;font-weight: 600;}
.books.info span { display: block;font-size: 0.9rem;}
.books.info .tags {color:blue;font-weight: 600;font-size: 0.7rem;margin-top:5px;}
.books.info img { height: 45px;margin-top:5px;cursor: pointer;margin-right: 10px;}

.books.grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 50px;
    margin-top:50px;
}
